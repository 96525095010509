.singlePost {
    flex: 9;
  }
  
  .singlePostWrapper {
    padding: 20px;
    padding-right: 0;
  }
  
  .singlePostImg {
    width: 100%;
    height: 300px;
    border-radius: 5px;
    object-fit: cover;
  }
  
  .singlePostTitle {
    text-align: center;
    margin: 10px;
    font-size: 35px;
    font-family: "Lora", sans-serif;
  }
  
  .singlePostEdit {
    float: right;
    font-size: 16px;
  }
  
  .singlePostIcon {
    margin-left: 10px;
    cursor: pointer;
  }
  
  .singlePostIcon:first-child {
    color: rgb(70, 146, 146);
  }
  .singlePostIcon:last-child {
    color: tomato;
  }
  
  .singlePostInfo {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    color:#9F8772;
    font-family: 'Tiro Devanagari Sanskrit', serif;
  }
  
  .singlePostAuthor{
    margin-left: 5px;
  }
  
  .singlePostDesc {
    color: #666;
    font-size: 18px;
    line-height: 25px;
  }
  
  .singlePostDesc::first-letter {
    margin-left: 20px;
    font-size: 30px;
    font-weight: 600;
  }