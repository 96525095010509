/* .header{
    margin-top: 70px;
} */
.headerTitles{
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #444;
    
}

/* .headerTitlesSm {
    position: absolute;
    top: 7%;
    font-size: 45px;
    font-family: 'Lora', serif;
} */

.headerTitlesLg{
    position: absolute;
    top: 6.5%;
    font-size: 120px;
    font-family: 'Great Vibes', cursive;
    margin: 20px;
}

.headerImg{
    width: 1440px;
}
