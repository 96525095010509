.settings {
    display: flex;
  }
  
  .settingsWrapper {
    flex: 9;
    padding: 20px;
  }
  
  .settingsTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .settingsTitleUpdate {
    font-size: 30px;
    margin-bottom: 20px;
    color: #665A48;
  }
  
  .settingsTitleDelete {
    color: #DC3535;
    font-size: 12px;
    cursor: pointer;
  }
  
  .settingsForm {
    display: flex;
    flex-direction: column;
  }
  
  .settingsPP {
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
  }
  
  .settingsPP > img {
    height: 80px;
    width: 80px;
    border-radius: 9px;
    object-fit: cover;
    -webkit-border-radius: 9px;
    -moz-border-radius: 9px;
    -ms-border-radius: 9px;
    -o-border-radius: 9px;
}
  
  .settingsPPIcon {
    width: 25px;
    height: 25px;
    padding: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 50%;
    margin-left: 40px;
    color: white;
    background-color: #889EAF;
    cursor: pointer;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    -o-border-radius: 50%;
}
  
  .settingsPPButton:focus {
    outline-style: none;
  }
  
  .settingsForm > label {
    font-size: 20px;
    margin-top: 20px;
  }
  
  .settingsPPInput {
    width: 30%;
  }
  
  .settingsForm > input {
    color: gray;
    margin-top: 10px;
    margin-bottom: 10px;
    height: 30px;
    border: none;
    border-bottom: 1px solid lightgray;
  }
  
  .settingsSubmitButton {
    align-self: center;
    width: 150px;
    border: none;
    border-radius: 10px;
    color: white;
    background-color: #889EAF;
    padding: 10px;
    margin-top: 20px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .settingsSubmitButton:hover {
    background-color:#889EAF;
  }